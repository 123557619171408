<template>
  <v-container>
    <v-row>
      <v-col
          cols="12"
        >
          <span>Each</span>
          <!-- <msnl-editor taskName="iterator" :value="iterator.M.each" @input="iterator.M.each = $event"></msnl-editor> -->
        </v-col>
        <v-col>
          <v-text-field
            dense
            v-model="iterator.M.each.VAR"
            required
            :rules="[fieldRequire]"
          ></v-text-field>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'ProcessorIteratorForm',
  model: {
    prop: 'iterator'
  },
  components: {
    // 'msnl-editor': () => import('@/components/MSNLEditor.vue')
  },
  props: {
    iterator: {
      type: Object,
      default () {
        return {
        }
      },
      required: true
    }
  },
  data: () => ({
  }),
  computed: {
    fieldRequire: function () {
      return v => !!v || 'field is required'
    },
    counterRequire: function (lessLength, moreLength) {
      if (lessLength && moreLength) {
        return v => ((v || '').length >= lessLength && (v || '').length <= moreLength) || `field must be ${lessLength}-${moreLength} characters`
      } else {
        return v => (v || '').length <= lessLength || `field must be ${lessLength} characters`
      }
    }
  },
  methods: {
  }
}
</script>
